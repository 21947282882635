/* left div, divider and right div */
.split-parent {
    position: static !important;
}
#left-pane {
    height: 100%;
}
.Resizer {
    width: 0.5%;
    background-color: #616161;
    position: relative;
    cursor: col-resize;
}
#right-pane {
    overflow-y: auto;
    height: 100%;
}
.Resizer,
#left-pane,
#right-pane {
    margin: 0;
    padding: 0;
}
.top-label {
    background-color: #0b0b0b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;
    color: white;
    font-size: 17px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.5px;
    word-spacing: 2px;
}
.label-top-icon {
    color: white;
    margin-right: 15px;
}
.instruct {
    color: #6e6e6e;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    word-spacing: 2px;
}
.edit-info {
    margin-top: 32px;
    color: #6e6e6e;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    word-spacing: 2px;
}
.terms {
    margin-top: 10px;
    color: #6e6e6e;
    background-color: transparent;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    word-spacing: 2px;
}
.publish-article-heading {
    text-align: center;
    background-color: white;
    letter-spacing: -0.1px;
    word-spacing: 3px;
    font-size: 36px;
}
.author-info,
.article-edit-content {
    font-family: 'Open Sans', sans-serif;
}
.submit-author-title {
    font-family: 'Carme', sans-serif;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    padding-top: 13px;
}
.editor-author-label {
    font-family: 'Carme', sans-serif;
    font-size: 20px;
    word-spacing: 2px;
}
.editor-author-bio textarea {
    width: 100%;
    font-size: 15px;
    resize: none;
    overflow: hidden;
}
.editor-author-bio textarea:focus {
    outline: none;
    border: 1px solid @primary-color;
    box-shadow: 0 0 10px @primary-color;
}
.editor-author-social-input {
    font-size: 15px;
}
.editor-author-social-input input {
    margin-left: 20px;
}
.author-name-input-wrapper::after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
}
.article-edit-label {
    font-size: 23px;
    font-family: 'Carme', sans-serif;
    display: table-cell;
    vertical-align: middle;
}
.article-edit-label span {
    color: @primary-color;
    margin-right: 5px;
}
.article-input-counter {
    color: rgba(0, 0, 0, 0.45);
}
.title-loading-icon {
    color: @primary-color;
}
.sun-editor-editable {
    code {
        background-color: #e4f9e3;
        color: #21855a;
    }
}

.sun-editor-editable {
    .output_pre_wrapper {
        margin-top: -8px !important;
        background-color: #efefef !important;
        border: solid 1px #e2e2e2;
        color: rgba(0, 0, 0, 0.9);
        font-family: monospace !important;
        font-size: 16px !important;
        padding: 5px 14px;
        line-height: 1.9 !important;
    }
    .output_pre_wrapper p {
        font-family: monospace;
        font-size: 16px;
        margin: 0;
    }
    pre {
        background-color: black !important;
        border-left: 5px solid #208031 !important;
        border-top: none !important;
        border-radius: 0px !important;
        color: white !important;
        font-size: 15px !important;
        word-spacing: 1px !important;
        padding: 15px 20px !important;
    }
    .code-wrapper {
        margin: 0;
        padding: 5px;
        border: 1px dashed #cccccc;
        border-radius: 5px;
    }
    /* code output */
    .lang_ul {
        list-style: none;
        margin: 0 0 0px 5px;
        padding: 0;
        line-height: 1 !important;
    }
    .lang_ul li {
        display: inline-block;
        color: #cdcdcd;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.6px;
        background-color: #262525;
        padding: 10px 15px;
        margin-top: 0px !important;
        margin-right: 3px;
        margin-bottom: 0px;
    }
    .lang_ul li:hover {
        cursor: pointer;
    }
    li.active-tab {
        color: white;
        background-color: black;
    }
    .highlight {
        margin-top: 0px !important;
    }
    .code_eg {
        margin-top: 0px !important;
    }
    .code_eg.active-code {
        display: block;
    }
    .code_eg.hidden-code {
        display: none;
    }
    .multi-code-div-wrapper {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

.multi-code-button {
    width: 90px !important;
}

.single-language-select {
    width: 100% !important;
}

.multiple-language-select {
    height: 200px !important;
    width: 100% !important;
    overflow: auto !important;
}

.multi-code-div-wrapper {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
