@import 'font-awesome/css/font-awesome.min.css';
#cover_div {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
#article_img {
    height: 500px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
}
#img_cover {
    padding: 35px 40px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
#article_heading {
    text-align: center;
    font-family: 'Alice', serif;
    font-weight: 400;
    font-size: 32px;
    word-spacing: 4px;
    margin-bottom: 26px;
}
#art_div {
    text-align: center;
}
#date {
    font-size: 12px;
    margin-right: 20px;
}
#date_text {
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 1px;
    margin-left: 8px;
}
#tags {
    font-size: 12px;
    cursor: pointer;
}
#tags_text {
    margin-left: 8px;
}
#tags_text a {
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 1px;
    color: #a0a0a0;
    text-decoration: none;
}
#tags_text a:hover,
.tags_text a:focus {
    color: #d2decb;
}
/* media queries */
@media screen and (max-width: 767px) {
    #article_heading {
        font-size: 30px;
    }
}
@media screen and (max-width: 600px) {
    #article_heading {
        font-size: 26px;
    }
    #date {
        font-size: 11px;
    }
    #tags {
        font-size: 11px;
    }
}
@media screen and (max-width: 600px) {
    #article_heading {
        font-size: 24px;
    }
    #date {
        font-size: 10px;
    }
    #tags {
        font-size: 10px;
    }
}
@media screen and (max-width: 400px) {
    #date {
        font-size: 9px;
    }
    #tags {
        font-size: 9px;
    }
}
/* article body */
#article-preview-wrapper {
    padding-left: 20px;
    padding-right: 20px;
}
#article {
    margin-top: 40px;
    overflow: auto;
}
#article p:first-child {
    margin-top: 0px;
}
#article img {
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 100%;
    height: auto;
}
#article p {
    font-family: 'Alice', serif;
    line-height: 1.9;
    word-spacing: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px !important;
}
#article div,
#article span {
    font-family: 'Alice', serif;
    line-height: 1.9;
    word-spacing: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px !important;
}
#article h2 {
    font-family: 'Alice', serif;
    font-size: 28px;
}
#article h3 {
    font-family: 'Alice', serif;
    font-size: 21px;
}
#article h4 {
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 18px;
}
#article strong {
    color: inherit;
}
#article b {
    color: inherit;
}
#article i {
}
#article em {
}
#article u {
    text-decoration: none;
    border-bottom: 2px solid #2baa74;
}
#article s {
}
#article blockquote {
    padding-left: 20px;
    border-left: solid 3px #2baa74;
    font-family: 'Alice', serif;
    font-weight: 200;
    line-height: 1.9;
    word-spacing: 2px;
    font-style: italic;
    color: #666;
}
#article blockquote {
    * {
        margin: 0px;
        padding: 0px;
    }
}
#article ul {
    font-size: 18px !important;
    font-family: 'Alice', serif;
    font-weight: 200;
    line-height: 1.9;
    word-spacing: 2px;
}
#article ul li:not(:first-child) {
    margin-top: 20px;
}
#article ol {
    font-size: 18px !important;
    font-family: 'Alice', serif;
    font-weight: 200;
    line-height: 1.9;
    word-spacing: 2px;
}
#article ol li:not(:first-child) {
    margin-top: 20px;
}
#article a {
    font-family: 'Alice', serif;
    font-weight: 200;
    line-height: 1.9;
    word-spacing: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #279a69;
    text-decoration: none;
    cursor: pointer;
}
#article a:hover,
#article a:focus {
    color: #2baa74;
}
#article table {
    width: 95%;
    margin: 30px auto;
    box-shadow: 0px 0px 5px 0px #888;
    overflow: auto;
}
#article th {
    padding: 10px;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.7px;
    font-weight: 700;
    background-color: #1d7650;
    color: white;
}
#article table th > * {
    margin: 0;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
}
#article table td > * {
    margin: 0;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
}
#article table td {
    padding: 10px 8px;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    border: 1px solid #dfdfdf;
}
#article table tr:hover,
.article table tr:focus {
    background-color: #efefef;
}
#article table,
th,
td {
    border-collapse: collapse;
}
#article caption {
    caption-side: top;
    font-size: 15px;
    font-family: 'Carme', sans-serif;
    font-weight: 700;
}
#article code {
    background-color: #e4f9e3;
    color: #21855a;
}
@media screen and (max-width: 600px) {
    #article p {
        font-size: 18px;
    }
    #article h2 {
        font-family: 'Alice', serif;
        font-size: 24px;
    }
    #article h3 {
        font-family: 'Alice', serif;
        font-size: 20px;
    }
}
/* preview instruction */
#preview_ins {
    background-color: #222222;
    color: white;
    text-align: center;
    padding: 10px 13px;
    font-size: 14px;
    font-family: 'Carme', sans-serif;
    word-spacing: 1px;
    border-radius: 5px;
    margin-bottom: 30px;
}
/* author */
#art_down {
    margin-top: 100px;
    margin-bottom: 80px;
}
#author_left {
    border: solid 1px #d1d1d1;
    padding: 20px 17px;
    font-size: 13px;
    -moz-box-shadow: 2px 2px 5px rgba(143, 143, 143, 1), -1px -1px 5px #ebebeb;
    -webkit-box-shadow: 2px 2px 5px rgba(143, 143, 143, 0.7), -1px -1px 5px #ebebeb;
    box-shadow: 2px 2px 5px #efefef, -2px -2px 5px #efefef /* ,-1px 1px 5px #EBEBEB,1px -1px 5px #EBEBEB */;
}
#author_text {
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 1px;
    font-weight: 700;
    cursor: pointer;
}
#author_text:hover,
#author_text:focus {
    color: #249364;
}
#author_intro {
    margin-top: 10px;
    margin-bottom: 17px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
    color: #666;
}
#author_social1 i {
    font-size: 16px;
    color: #2baa74;
    padding: 4px;
    cursor: pointer;
}
#article pre {
    background-color: black !important;
    border-left: 5px solid #208031 !important;
    border-top: none !important;
    border-radius: 0px !important;
    color: white !important;
    font-size: 15px !important;
    word-spacing: 1px !important;
    padding: 15px 20px !important;
}
#article hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}
#article {
    .__se__solid {
        border-top: 1px solid #eee;
    }
    .__se__dashed {
        border-top: 1px dashed #eee;
    }
    .__se__dotted {
        border-top: 1px dotted #eee;
    }
    .multi-code-div-wrapper {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
.output_pre_wrapper {
    margin-top: -8px !important;
    background-color: #efefef !important;
    border: solid 1px #e2e2e2;
    color: rgba(0, 0, 0, 0.9);
    font-family: monospace !important;
    font-size: 16px !important;
    padding: 5px 14px;
    line-height: 1.9 !important;
}
.output_pre_wrapper p {
    font-family: monospace;
    font-size: 16px;
    margin: 0;
}
/* code output */
.lang_ul {
    list-style: none;
    margin: 0 0 0px 5px;
    padding: 0;
    line-height: 1 !important;
}
.lang_ul li {
    display: inline-block;
    color: #cdcdcd;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.6px;
    background-color: #262525;
    padding: 10px 15px;
    margin-top: 0px !important;
    margin-right: 3px;
}
.lang_ul li:hover {
    cursor: pointer;
}
li.active-tab {
    color: white;
    background-color: black;
}
.highlight {
    margin-top: 0px !important;
}
.code_eg {
    margin-top: 0px !important;
}
.code_eg.active-code {
    display: block;
}
.code_eg.hidden-code {
    display: none;
}
