@font-face {
  font-family: 'Alice';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/alice/v12/OpNCnoEEmtHa6GcOrgs.ttf) format('truetype');
}
@font-face {
  font-family: 'Carme';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/carme/v11/ptRHTiWdbvZIDNjBzrc.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0e.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfedw.ttf) format('truetype');
}
