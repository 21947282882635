.nav-header {
    -webkit-box-shadow: 0 8px 6px -6px #999;
    -moz-box-shadow: 0 8px 6px -6px #999;
    box-shadow: 0 8px 6px -6px #999;
}
.logout-button {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.header-logo {
    height: @layout-header-height;
}
.header-menu {
    font-size: 16px;
}
