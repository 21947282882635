#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
.App {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
.body-height {
    flex: 1;
}
.float-right {
    float: right;
}
.float-left {
    float: left;
}
.center-align {
    text-align: center;
}
.left-align {
    text-align: left;
}
.right-align {
    text-align: right;
}
.place-center {
    margin-right: auto;
    margin-left: auto;
}
.mt-s {
    margin-top: 5px;
}
.mt {
    margin-top: 10px;
}
.mt-l {
    margin-top: 20px;
}
.mt-xl {
    margin-top: 35px;
}
.mt-xxl {
    margin-top: 50px;
}
.mt-xxxl {
    margin-top: 75px;
}
.mt-xxxl {
    margin-top: 100px;
}
.mr-s {
    margin-right: 5px;
}
.mr {
    margin-right: 10px;
}
.mr-l {
    margin-right: 20px;
}
.mr-xl {
    margin-right: 35px;
}
.mr-xxl {
    margin-right: 50px;
}
.mr-xxxl {
    margin-right: 75px;
}
.mr-xxxl {
    margin-right: 100px;
}
.mb-s {
    margin-bottom: 5px;
}
.mb {
    margin-bottom: 10px;
}
.mb-l {
    margin-bottom: 20px;
}
.mb-xl {
    margin-bottom: 35px;
}
.mb-xxl {
    margin-bottom: 50px;
}
.mb-xxxl {
    margin-bottom: 75px;
}
.mb-xxxl {
    margin-bottom: 100px;
}
.ml-s {
    margin-left: 5px;
}
.ml {
    margin-left: 10px;
}
.ml-l {
    margin-left: 20px;
}
.ml-xl {
    margin-left: 35px;
}
.ml-xxl {
    margin-left: 50px;
}
.ml-xxxl {
    margin-left: 75px;
}
.ml-xxxl {
    margin-left: 100px;
}
.pt-s {
    padding-top: 5px;
}
.pt {
    padding-top: 10px;
}
.pt-l {
    padding-top: 20px;
}
.pt-xl {
    padding-top: 35px;
}
.pt-xxl {
    padding-top: 50px;
}
.pt-xxxl {
    padding-top: 75px;
}
.pt-xxxl {
    padding-top: 100px;
}
.pr-s {
    padding-right: 5px;
}
.pr {
    padding-right: 10px;
}
.pr-l {
    padding-right: 20px;
}
.pr-xl {
    padding-right: 35px;
}
.pr-xxl {
    padding-right: 50px;
}
.pr-xxxl {
    padding-right: 75px;
}
.pr-xxxl {
    padding-right: 100px;
}
.pb-s {
    padding-bottom: 5px;
}
.pb {
    padding-bottom: 10px;
}
.pb-l {
    padding-bottom: 20px;
}
.pb-xl {
    padding-bottom: 35px;
}
.pb-xxl {
    padding-bottom: 50px;
}
.pb-xxxl {
    padding-bottom: 75px;
}
.pb-xxxl {
    padding-bottom: 100px;
}
.pl-s {
    padding-left: 5px;
}
.pl {
    padding-left: 10px;
}
.pl-l {
    padding-left: 20px;
}
.pl-xl {
    padding-left: 35px;
}
.pl-xxl {
    padding-left: 50px;
}
.pl-xxxl {
    padding-left: 75px;
}
.pl-xxxl {
    padding-left: 100px;
}
.wrap-width {
    display: inline-block;
}
.cursor-pointer {
    cursor: pointer;
}
.fullscreen-spin {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    padding-top: 10%;
    background-color: rgba(255, 255, 255, 0.8);
}
